.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust as needed */
   }
   
   form {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
   }